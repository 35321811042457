<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑供应商' : '创建供应商'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <div class="itemTime">基础信息</div>
        <el-form-item label="名称" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input
            v-model.trim="form.data.contact"
            maxlength="11"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contact_phone">
          <el-input
            v-model.trim="form.data.contact_phone"
            maxlength="11"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入"
            clearable
            :disabled="isChange"
          ></el-input>
        </el-form-item>
        <!-- 超级管理员选地区 -->
        <el-form-item label="供货地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            filterable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="提供代分拣" prop="is_sorter">
          <el-radio-group v-model="form.data.is_sorter" v-removeAriaHidden>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
          <el-popover
            placement="top"
            width="300"
            v-model="visiblePopover">
            <p style="text-indent: 25px;padding:0 5px;">
              提供代分拣指供应商供应商品将按照客户下单食堂分别拣好并投篮，
              若提供该服务请选择是（采购收货将需要您根据不同食堂采购数量逐一登记）；
              若不提供您可选择否（采购收货将统一采购统一登记收货）</p>
            <div style="text-align: center; margin: 0">
              <el-button type="text" size="mini" @click="visiblePopover = false">我知道了</el-button>
            </div>
            <i slot="reference" class="el-icon-question" style="padding:0 10px;margin-left: 50px;font-size: 20px;color: #2cb167;"></i>
          </el-popover>
        </el-form-item>
        <!-- <el-form-item label="供应客户" prop="merchant_ids">
          <el-select v-model="form.data.merchant_ids" multiple filterable style="width:100%;">
            <el-option
            v-for="item in merchantArr"
            :label="item.name"
            :value="item.id"
            :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <div class="itemTime">开票信息</div>
        <el-form-item label="开户名称" prop="bank_account">
          <el-input
            v-model.trim="form.data.bank_account"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank_name">
          <el-input
            v-model.trim="form.data.bank_name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="bank_code">
          <el-input
            v-model.trim="form.data.bank_code"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="税号" prop="tax_code">
          <el-input
            v-model.trim="form.data.tax_code"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'SupplierEdit',
    data() {
      return {
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: "", //名称
            contact: "", //联系人
            contact_phone: "", //联系人电话
            account: "", //账户
            province: "", //省
            city: "", //市
            area: '',
            bank_account: "", //开户名称
            bank_name: "", //开户银行
            bank_code: "", //银行账号
            tax_code: "", //税号
            is_sorter: 2, // 是否代分拣,默认否
            // merchant_ids: "", //供应客户 
          },
          rules: {
            name: [{ required: true, message:'请输入名称', trigger: 'change'}],
            contact: [{ required: true, message:'请输入联系人', trigger: 'change'}],
            contact_phone: [
              { required: true, message:'请输入联系电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            account: [{ required: true, message:'请输入登录账号', trigger: 'change'}],
            area: [{ required: true, message:'请选择供货地区', trigger: 'change'}],
            is_sorter: [{ required: true, message:'请选择', trigger: 'change'}],
          }
        },
        areaArr: [],
        merchantArr: [],
        visiblePopover: false,
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        this.getMerchant();
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.area = [row.province, row.city];
            // this.form.data.merchant_ids = !!row.merchant_ids ? row.merchant_ids.split("|").map(Number) : '';
          })
        }
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                  delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 获取客户列表
      getMerchant() {
        // type 1:列表 2:参数
        this.$http.get("/admin/group/merchantList", {params:{type:1,page:1,count:10000}}).then((res) => {
          if(res.code == 1) {
            this.merchantArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              contact: this.form.data.contact,
              contact_phone: this.form.data.contact_phone,
              account: this.form.data.account,
              province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
              city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
              bank_account: this.form.data.bank_account,
              bank_name: this.form.data.bank_name,
              bank_code: this.form.data.bank_code,
              tax_code: this.form.data.tax_code,
              is_sorter: this.form.data.is_sorter,
              // merchant_ids: !!this.form.data.merchant_ids ? this.form.data.merchant_ids.join("|") : '',
            };
            let apiUrl = this.isChange ? '/admin/supplier/edit' : '/admin/supplier/add'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
